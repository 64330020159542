import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import DropUploader from '../../../global/forms/Uploader';

const ManageUpload = ({
  formula,
  fileBrochure,
  setFileBrochure,
  fileLogo,
  setFileLogo
}) => {
  return (
    <>
      <Card className="mb-3">
        <Card.Header as="h5">Logo</Card.Header>
        <Card.Body className="bg-light">
          <DropUploader
            endpoint={`formulas/${formula.id}`}
            file={fileLogo}
            setFile={setFileLogo}
            fileContext="logo_url"
          />
        </Card.Body>
      </Card>
      <Card className="mb-3">
        <Card.Header as="h5">Plaquette</Card.Header>
        <Card.Body className="bg-light">
          <DropUploader
            endpoint={`formulas/${formula.id}`}
            file={fileBrochure}
            setFile={setFileBrochure}
            fileContext="file_url"
          />
        </Card.Body>
      </Card>
    </>
  );
};

ManageUpload.propTypes = {
  formula: PropTypes.object,
  fileBrochure: PropTypes.string,
  setFileBrochure: PropTypes.func,
  fileLogo: PropTypes.string,
  setFileLogo: PropTypes.func
};

export default ManageUpload;
