import jwtDecode from 'jwt-decode';
import { toast } from 'react-toastify';

export default function CheckTokenExpires(navigate) {
  if (
    localStorage.getItem('accessToken') &&
    jwtDecode(localStorage.getItem('accessToken')).exp < Date.now() / 1000
  ) {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('user');
    toast('Session expirée, veuillez vous reconnecter');
    navigate('/login');
  }
}
