import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import AdvanceTableSearchBox from '../../../global/tables/AdvanceTableSearchBox';
import AdvanceTableFooter from '../../../global/tables/AdvanceTableFooter';
import AdvanceTableWrapper from '../../../../common/advance-table/AdvanceTableWrapper';
import { IndexUser } from '../../../../../services/UserService';
import { toast } from 'react-toastify';
import IndexTable from './table';
import * as Moment from 'moment';
import PropTypes from 'prop-types';
import BulkActions from './BulkActions';

export default function UsersIndex() {
  const [users, setUsers] = useState('');
  const [trigger, setTrigger] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const cols = [
    {
      id: 'name',
      Header: 'Nom',
      accessor: d => {
        return d.name;
      }
    },
    {
      id: 'email',
      Header: 'Adresse mail de connexion',
      accessor: d => {
        return d.email;
      }
    },
    {
      id: 'last_login_at',
      Header: 'Dernière connexion',
      accessor: d => {
        return d.last_login_at
          ? Moment(d.last_login_at).local().format('DD/MM/YYYY H:mm')
          : '-';
      }
    },
    {
      id: 'created_at',
      Header: 'Date de création du compte',
      accessor: d => {
        return Moment(d.created_at).local().format('DD/MM/YYYY');
      }
    }
  ];

  useEffect(() => {
    const fetchData = async () => {
      const response = await IndexUser();
      if (response.success === true) {
        setUsers(response.data);
        setIsLoading(false);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    setIsLoading(true);
    fetchData();
  }, [trigger]);

  return (
    <Card>
      <Card.Body className="overflow-hidden">
        <Row className="align-items-center justify-content-between">
          <Col className="ps-lg-4 my-1 text-center text-lg-start">
            <Row className="mb-4">
              <Col lg={12}>
                <h3 className="text-primary">Gestion des administrateurs</h3>
              </Col>
            </Row>
            {isLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <AdvanceTableWrapper
                columns={cols}
                data={users}
                sortable
                pagination
                perPage={10}
              >
                <Row className="flex-end-center mb-3">
                  <Col xs={9} sm={8} lg={8}>
                    <AdvanceTableSearchBox table />
                  </Col>
                  <BulkActions table setTrigger={setTrigger} />
                </Row>
                <IndexTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    bordered: true,
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
                  setTrigger={setTrigger}
                />
                <div className="mt-3">
                  <AdvanceTableFooter
                    rowCount={users.length}
                    table
                    rowInfo
                    navButtons
                  />
                </div>
              </AdvanceTableWrapper>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

UsersIndex.propTypes = {
  created_at: PropTypes.any
};
