import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../../../common/IconButton';
import { useNavigate } from 'react-router';

export default function Create({ category }) {
  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/formulas/${category}/create`;
    navigate(path);
  };
  return (
    <>
      <IconButton
        variant="falcon-default"
        size="sm"
        icon="plus"
        transform="shrink-3"
        className=""
        onClick={() => routeChange()}
      >
        <span className="d-none d-sm-inline-block ms-1">Ajouter</span>
      </IconButton>
    </>
  );
}

Create.propTypes = {
  setTrigger: PropTypes.func,
  category: PropTypes.string
};
