import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'formulas';

export function getFormulasWordpressUrl() {
  return 'https://mnspf.fr/';
}

export async function IndexFormula(category) {
  if (category !== undefined && category !== null) {
    return modelService.IndexModel(`${model}-admin?category=${category}`);
  } else {
    return modelService.IndexModel(`${model}-admin`);
  }
}

export async function ShowFormula(id) {
  return modelService.ShowModel(`${model}/${id}`);
}

export async function PostFormula(formData) {
  return modelService.PostModel(model, formData);
}

export async function PutFormula(id, formData) {
  return modelService.PostModel(`${model}/${id}`, formData);
}

export async function DeleteFormula(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
