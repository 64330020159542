import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Greetings from './Greetings';

const Dashboard = () => {
  return (
    <>
      <Row className="g-3 mb-3">
        <Col xxl={12} lg={12}>
          <Greetings />
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
