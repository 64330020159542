import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';

const FormAlert = ({ data, variant }) => {
  try {
    const parse = Object.values(JSON.parse(data));
    return parse.map((item, field) => (
      <Alert key={field} variant={variant}>
        {item}
      </Alert>
    ));
  } catch (e) {
    return <Alert variant={variant}>{data}</Alert>;
  }
};

FormAlert.propTypes = {
  message: PropTypes.string,
  validator: PropTypes.string,
  variant: PropTypes.string
};

export default FormAlert;
