import ModelService from './ModelService';
const modelService = new ModelService();

const model = 'procedures';

export async function IndexProcedures(category) {
  if (category !== undefined && category !== null) {
    return modelService.IndexModel(
      `procedure-categories-admin?formula_category=${category}`
    );
  } else {
    return modelService.IndexModel('procedure-categories-admin');
  }
}

export async function ShowProcedureCategory(id) {
  return modelService.ShowModel(`procedure-categories/${id}`);
}

export async function PostProcedureCategory(formData) {
  return modelService.PostModel('procedure-categories', formData);
}

export async function PutProcedureCategory(id, formData) {
  return modelService.PostModel(`procedure-categories/${id}`, formData);
}

export async function PostProcedure(category, formData) {
  return modelService.PostModel(
    `procedure-categories/${category}/${model}`,
    formData
  );
}

export async function PutProcedure(id, formData) {
  return modelService.PutModel(
    `procedure-categories/${id}/procedures`,
    formData
  );
}

export async function PutExamples(id, formData) {
  return modelService.PutModel(`procedure-categories/${id}/examples`, formData);
}

export async function DeleteProcedureCategory(id) {
  return modelService.DeleteModel(`procedure-categories/${id}`);
}

export async function DeleteProcedure(id) {
  return modelService.DeleteModel(`${model}/${id}`);
}
