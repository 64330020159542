import React, { useEffect, useState } from 'react';
import { Accordion, Button, Card, Col, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import PageHeader from '../../../common/PageHeader';
import {
  IndexProcedures,
  ShowProcedureCategory
} from '../../../../services/ProcedureService';
import { Table } from 'react-bootstrap';
import Manage from './Manage';
import { IndexFormula } from '../../../../services/FormulaService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ProceduresExamplesIndex({ category }) {
  const [procedureCategories, setProcedureCategories] = useState([]);
  const [trigger, setTrigger] = useState(Date.now());
  const [isLoading, setIsLoading] = useState(true);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(false);
  const [formulas, setFormulas] = useState('');
  const [showManage, setShowManage] = useState(false);
  const [defaultAccordionActive, setDefaultAccordionActive] = useState(false);

  const getCategoryLabel = () => {
    if (category === 'complementaires') {
      return 'complémentaire';
    } else if (category === 'surcomplementaires') {
      return 'sur-complémentaire';
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await IndexProcedures(category);
      if (response.success === true) {
        setProcedureCategories(response.data);
        if (response.data.length > 0 && !defaultAccordionActive) {
          setDefaultAccordionActive(response.data[0].id);
        }
        const responseFormulas = await IndexFormula(category);
        if (responseFormulas.success === true) {
          setFormulas(responseFormulas.data);
          setIsLoading(false);
        } else {
          toast('Une erreur est survenue, veuillez réessayer plus tard');
        }
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    setIsLoading(true);
    fetchData();
  }, [trigger]);

  const handleManageShow = category => {
    setButtonIsLoading(true);
    let id = category.id;
    const fetchData = async () => {
      const response = await ShowProcedureCategory(id);
      if (response.success === true && response.data) {
        return response.data;
      }
    };
    fetchData().then(data => {
      setSelectedCategory(data);
      setShowManage(true);
      setButtonIsLoading(false);
    });
  };

  return (
    <>
      <PageHeader
        title="Exemples pour le simulateur d'actes médicaux"
        description={`Gérez ici le simulateur d'actes médicaux présenté sur le site. Pour chaque groupe d'acte ajouté <b>depuis la section Actes médicaux</b>, vous pouvez configurer l'image affichée dans le simulateur et les exemples de soins. Pour chaque exemple de soin, configurez les données correspondantes (prix moyen de l'acte, prise en charge sécurité sociale, prise en charge pour chaque ${getCategoryLabel()}...).`}
        className="mb-3"
      />
      <Card>
        <Card.Body>
          {isLoading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            <>
              <Row className="mb-3">
                <Col className="text-end">
                  <Manage
                    setTrigger={setTrigger}
                    selectedCategory={selectedCategory}
                    setSelectedCategory={setSelectedCategory}
                    showManage={showManage}
                    setShowManage={setShowManage}
                    formulas={formulas}
                    defaultAccordionActive={defaultAccordionActive}
                    setDefaultAccordionActive={setDefaultAccordionActive}
                    buttonIsLoading={buttonIsLoading}
                    setButtonIsLoading={setButtonIsLoading}
                  />
                </Col>
              </Row>
              <Accordion
                id="accordionFaq"
                className="border rounded overflow-hidden"
                defaultActiveKey={`${defaultAccordionActive}`}
              >
                {procedureCategories.map(category => (
                  <Accordion.Item
                    eventKey={`${category.id}`}
                    key={`${category.id}`}
                  >
                    <Accordion.Header>
                      <span style={{ fontWeight: 700 }}>{category.name}</span>
                    </Accordion.Header>
                    <Accordion.Body style={{ padding: '10px 0' }}>
                      <Table className="mt-1">
                        <thead>
                          <tr>
                            <td valign="middle">
                              <Button
                                onClick={() => handleManageShow(category)}
                                size="sm"
                                variant="falcon-primary"
                                className="me-2 mb-1"
                                disabled={buttonIsLoading}
                              >
                                {buttonIsLoading ? (
                                  'Chargement…'
                                ) : (
                                  <>
                                    <FontAwesomeIcon
                                      icon="cog"
                                      className="me-1"
                                    />
                                    Gérer
                                  </>
                                )}
                              </Button>
                            </td>
                            {formulas.map((formula, index) => (
                              <td
                                key={index}
                                style={{ fontSize: '14px', padding: '6px' }}
                                className="text-center"
                                valign="middle"
                              >
                                <small>
                                  <small>Prise en charge</small>
                                </small>
                                <br />
                                <b>{formula.title}</b>
                              </td>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {category.examples.map((example, indexE) => (
                            <tr key={indexE}>
                              <td
                                style={{
                                  fontSize: '14px',
                                  padding: '15px 0 6px 20px',
                                  width: '150px'
                                }}
                                valign="middle"
                              >
                                <small>{example.name}</small>
                                <hr className="mb-1" />
                                <small>
                                  Prix moyen de l'acte : {example.average_price}
                                  €
                                  <br />
                                  Prise en ch. sécurité sociale :{' '}
                                  {example.average_support}€
                                </small>
                              </td>
                              {formulas.map(formula => {
                                return example.formulas.map(
                                  (procedureFormula, index) => {
                                    if (formula.id === procedureFormula.id) {
                                      return (
                                        <td
                                          style={{
                                            fontSize: '14px',
                                            padding: '6px'
                                          }}
                                          key={index}
                                          className="text-center"
                                          valign="middle"
                                        >
                                          {procedureFormula.pivot.coverage}€
                                        </td>
                                      );
                                    }
                                  }
                                );
                              })}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
}

ProceduresExamplesIndex.propTypes = {
  created_at: PropTypes.any,
  category: PropTypes.string
};
