import React, { useState } from 'react';
import { Button, Modal, Form, Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';
import FormAlert from '../../global/forms/FormAlert';
import { toast } from 'react-toastify';
import IconButton from '../../../common/IconButton';
import { PostUser } from '../../../../services/UserService';

export default function Create({ setTrigger }) {
  const [modalShow, setModalShow] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: ''
  });

  const submit = async e => {
    e.preventDefault();
    const response = await PostUser(formData);
    setErrorForm(response.validator);
    if (response.success === true) {
      toast(
        'Administrateur ajouté, la personne concernée va reçevoir un email de connexion'
      );
      setModalShow(!modalShow);
      setFormData({
        name: ''
      });
      setTrigger(Date.now());
    }
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <>
      <IconButton
        variant="falcon-default"
        size="sm"
        icon="plus"
        transform="shrink-3"
        className=""
        onClick={() => setModalShow(true)}
      >
        <span className="d-none d-sm-inline-block ms-1">
          Créer un compte administrateur
        </span>
      </IconButton>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Ajouter un administrateur
          </Modal.Title>
        </Modal.Header>
        <Form>
          <Modal.Body>
            {errorForm && <FormAlert variant="danger" data={errorForm} />}
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Nom de l'administrateur</Form.Label>
              <Form.Control
                type="text"
                placeholder="Indiquer le nom de l'administrateur"
                name="name"
                value={formData.name}
                onChange={handleFieldChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Adresse email de connexion</Form.Label>
              <Form.Control
                type="email"
                placeholder="Indiquer l'adresse email de l'administrateur"
                name="email"
                value={formData.email}
                onChange={handleFieldChange}
              />
            </Form.Group>
            <Alert variant="primary">
              {formData.email ? (
                <b>{formData.email}</b>
              ) : (
                'Le nouvel administrateur'
              )}{' '}
              va reçevoir un email automatique de connexion à son nouveau
              compte.
            </Alert>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              onClick={e => {
                submit(e);
              }}
            >
              Valider la création du compte
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

Create.propTypes = {
  setTrigger: PropTypes.func
};
