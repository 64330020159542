import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { DeleteUser } from '../../../../services/UserService';

export default function Delete({ model, setTrigger }) {
  const deleteFunction = async () => {
    const response = await DeleteUser(model.id);
    if (response.success === true) {
      toast('Administrateur supprimé');
    } else {
      toast('Une erreur est survenue');
    }
    setTrigger(Date.now());
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h5" style={{ fontSize: '14px' }}>
        La suppression est définitive
      </Popover.Header>
      <Popover.Body>
        <FontAwesomeIcon
          icon="exclamation-triangle"
          className="text-danger fa-fw mr-5"
        />
        {model.id !== JSON.parse(localStorage.getItem('user')).id ? (
          <span>
            <p>
              L'administrateur ne sera plus en mesure de se connecter à cette
              plateforme.
            </p>
            <a
              className="text-danger"
              href="#"
              onClick={() => {
                deleteFunction(model);
              }}
            >
              Oui, confirmer la suppression
            </a>
          </span>
        ) : (
          <p>
            Vous ne pouvez pas supprimer votre compte. Demandez à un autre
            administrateur ou contactez le service technique.
          </p>
        )}
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger trigger="focus" placement="right" overlay={popover}>
      <Button title="Delete" variant="action" className="p-0 me-2">
        <FontAwesomeIcon icon="trash-alt" />
      </Button>
    </OverlayTrigger>
  );
}

Delete.propTypes = {
  model: PropTypes.object,
  setTrigger: PropTypes.any
};
