export const dashboardRoutes = {
  label: 'Dashboard',
  children: [
    {
      name: 'Tableau de bord',
      icon: 'chart-pie',
      to: '/',
      active: true
    }
  ]
};
export const complementairesRoutes = {
  label: 'Complémentaires',
  children: [
    {
      name: 'Gestion des compl.',
      icon: 'table',
      to: '/formulas/complementaires',
      active: true
    },
    {
      name: 'Actes médicaux',
      icon: 'table',
      to: '/procedures/complementaires',
      active: true
    },
    {
      name: 'Simulateur',
      icon: 'table',
      to: '/procedures-examples/complementaires',
      active: true
    },
    {
      name: "Trouver l'offre idéale",
      icon: 'layer-group',
      to: '/simulators/mutuelle',
      active: true
    }
  ]
};
export const surcomplementairesRoutes = {
  label: 'Sur-complémentaires',
  children: [
    {
      name: 'Gestion des sur-compl.',
      icon: 'table',
      to: '/formulas/surcomplementaires',
      active: true
    },
    {
      name: 'Actes médicaux',
      icon: 'table',
      to: '/procedures/surcomplementaires',
      active: true
    }
  ]
};
export const usersRoutes = {
  label: 'Comptes',
  children: [
    {
      name: 'Administrateurs',
      icon: 'user',
      to: '/users',
      active: true
    }
  ]
};

export default [
  dashboardRoutes,
  complementairesRoutes,
  surcomplementairesRoutes,
  usersRoutes
];
