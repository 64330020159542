import React, { useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  FormControl,
  InputGroup,
  Offcanvas,
  Row
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import FormAlert from '../../global/forms/FormAlert';
import { toast } from 'react-toastify';
import IconButton from '../../../common/IconButton';
import {
  DeleteProcedureCategory,
  PostProcedure,
  PostProcedureCategory,
  PutProcedure,
  PutProcedureCategory
} from '../../../../services/ProcedureService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import DropUploader from '../../global/forms/Uploader';

export default function Manage({
  setTrigger,
  selectedCategory,
  setSelectedCategory,
  showManage,
  setShowManage,
  formulas,
  setDefaultAccordionActive,
  buttonIsLoading,
  setButtonIsLoading,
  category,
  procedureCategories
}) {
  const [errorForm, setErrorForm] = useState(false);
  const [showStep2, setShowStep2] = useState(false);
  const [showStep3, setShowStep3] = useState(false);
  const [proceduresForm, setProceduresForm] = useState([]);
  const [fileThumbnail, setFileThumbnail] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    category_name: category,
    order: 0
  });

  const handleOpen = () => {
    if (selectedCategory) {
      setFormData({
        name: selectedCategory.name,
        category_name: category,
        order: selectedCategory.order
      });
      setFileThumbnail(selectedCategory.thumbnail);
      if (selectedCategory.name) {
        setShowStep2(true);
      }
      if (selectedCategory.procedures.length) {
        let list = [...proceduresForm];
        selectedCategory.procedures.map(procedure => {
          list.push({
            name: procedure.name,
            order: procedure.order,
            formulas: procedure.formulas,
            id: procedure.id
          });
        });
        setProceduresForm(list);
      }
      setShowStep3(true);
    }
  };

  const handleClose = () => {
    setShowManage(false);
    setFormData({
      name: '',
      category_name: category,
      order: 0
    });
    setProceduresForm([]);
    setSelectedCategory(false);
    setShowStep2(false);
    setShowStep3(false);
  };

  const submit = async e => {
    setButtonIsLoading(true);
    e.preventDefault();
    let responseCategory = false;
    if (selectedCategory) {
      responseCategory = await PutProcedureCategory(
        selectedCategory.id,
        formData
      );
    } else {
      responseCategory = await PostProcedureCategory(formData);
    }

    setErrorForm(responseCategory.validator);
    if (responseCategory.success === true) {
      let responseProcedures = false;
      if (selectedCategory) {
        responseProcedures = await PutProcedure(
          responseCategory.data[0].id,
          proceduresForm
        );
      } else {
        responseProcedures = await PostProcedure(
          responseCategory.data[0].id,
          proceduresForm
        );
      }
      setErrorForm(responseProcedures.validator);
      if (responseProcedures.success === true) {
        toast('Groupe enregistré');
        setButtonIsLoading(false);
        setShowManage(!showManage);
        setFormData({
          name: '',
          category_name: category,
          order: 0
        });
        setProceduresForm([]);
        setShowStep2(false);
        setShowStep3(false);
        setFormData({
          name: '',
          category_name: category,
          order: 0
        });
        setProceduresForm([]);
        setSelectedCategory(false);
        setDefaultAccordionActive(responseCategory.data[0].id);
        setTrigger(Date.now());
      }
    }
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleProcedureChange = (e, index, indexProcedureFormula) => {
    const list = [...proceduresForm];
    if (e.target.name === 'coverage') {
      list[index].formulas[indexProcedureFormula].pivot.coverage =
        e.target.value;
      checkShowStep3(list);
    } else if (e.target.name === 'name') {
      list[index].name = e.target.value;
      checkShowStep3(list);
    } else if (e.target.name === 'order') {
      list[index].order = e.target.value;
      checkShowStep3(list);
    }
    setProceduresForm(list);
  };

  const removeCategory = async e => {
    e.preventDefault();
    const responseCategory = await DeleteProcedureCategory(selectedCategory.id);
    if (responseCategory.success === true) {
      toast('Catégorie supprimée');
      setShowManage(!showManage);
      setFormData({
        name: '',
        category_name: category,
        order: 0
      });
      setProceduresForm([]);
      setShowStep2(false);
      setShowStep3(false);
      setSelectedCategory(false);
      setTrigger(Date.now());
    }
  };

  const addProcedure = () => {
    const list = [...proceduresForm];
    list.push({
      name: ''
    });
    setProceduresForm(list);
    checkShowStep3(list);
  };

  const removeProcedure = index => {
    const list = [...proceduresForm];
    list[index].deleted = true;
    //list.splice(index, 1);
    setProceduresForm(list);
    checkShowStep3(list);
  };

  const checkShowStep3 = list => {
    if (showStep2 && list.length > 0) {
      setShowStep3(true);
      list.map(procedure => {
        if (procedure.name === '') {
          setShowStep3(false);
        }
      });
    } else if (showStep2) {
      setShowStep3(true);
    } else {
      setShowStep3(false);
    }
  };

  return (
    <>
      <Offcanvas
        placement={'end'}
        show={showManage}
        onShow={handleOpen}
        onHide={handleClose}
        style={{ width: 700, backgroundColor: '#EDF2F9' }}
      >
        <Offcanvas.Header closeButton={!selectedCategory}>
          <Row className="align-items-center" style={{ width: '100%' }}>
            <Col>
              <Offcanvas.Title>
                {selectedCategory ? 'Modifier le groupe' : 'Ajouter un groupe'}
              </Offcanvas.Title>
            </Col>
            <Col className="text-end">
              {selectedCategory && (
                <IconButton
                  variant="danger"
                  icon="trash"
                  className="mb-1"
                  iconAlign="right"
                  transform="shrink-3"
                  size="sm"
                  onClick={e => {
                    removeCategory(e);
                  }}
                >
                  Supprimer le groupe
                </IconButton>
              )}
            </Col>
          </Row>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {errorForm && <FormAlert variant="danger" data={errorForm} />}
          <Row className="g-4">
            <Col md>
              <Card bg="light" text="dark">
                <Card.Body>
                  <Card.Title as="div">
                    1 - Nom du groupe {selectedCategory && 'et illustration'}
                  </Card.Title>
                  <Card.Text>
                    <small>
                      Renseignez d'abord le nom du groupe qui va contenir la
                      liste des actes médicaux :
                    </small>
                  </Card.Text>
                  <Form.Group
                    className="mt-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Exemple : Soins courants, Hospitalisation..."
                      name="name"
                      value={formData.name}
                      onChange={handleFieldChange}
                    />
                  </Form.Group>
                  {selectedCategory ? (
                    <>
                      {category !== 'surcomplementaires' && (
                        <>
                          <Card.Text className="mb-0 mt-2">
                            <small>
                              Son ordre d'affichage dans le simulateur{' '}
                              <small>
                                <i>s'affiche du plus petit au plus grand</i>
                              </small>{' '}
                              :
                            </small>
                          </Card.Text>
                          <InputGroup className="mb-2 mt-2">
                            <InputGroup.Text id="basic-addon1">
                              Ordre :
                            </InputGroup.Text>
                            <FormControl
                              placeholder="1"
                              name="order"
                              min="1"
                              max={procedureCategories.length}
                              onKeyDown={e => {
                                e.preventDefault();
                              }}
                              value={formData.order}
                              className="text-end"
                              type="number"
                              onChange={handleFieldChange}
                            />
                            <InputGroup.Text>
                              /{procedureCategories.length}
                            </InputGroup.Text>
                          </InputGroup>
                        </>
                      )}

                      <Card.Text className="mb-0 mt-2">
                        <small>
                          Et éventuellement l'illustration qui est présentée sur
                          le simulateur :
                        </small>
                      </Card.Text>
                      <DropUploader
                        endpoint={`procedure-categories/${selectedCategory.id}`}
                        file={fileThumbnail}
                        setFile={setFileThumbnail}
                        accept="image/*"
                        showAsImage={true}
                      />
                    </>
                  ) : (
                    <>
                      {showStep2 && (
                        <Row>
                          <Col>
                            <small>
                              <small>
                                <b className="text-info">
                                  <FontAwesomeIcon icon={faInfoCircle} /> Vous
                                  pourrez configurer l'illustration du groupe
                                  (présentée dans le simulateur) après
                                  enregistrement du formulaire.
                                </b>
                              </small>
                            </small>
                          </Col>
                        </Row>
                      )}
                    </>
                  )}
                  {formData.name && !showStep2 && (
                    <Form.Group
                      className="mt-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Button
                        type="button"
                        size="sm"
                        onClick={() => {
                          setShowStep2(true);
                        }}
                      >
                        Suivant
                      </Button>
                    </Form.Group>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {showStep2 && formData.name && (
            <Row className="g-4 mt-0">
              <Col md>
                <Card bg="light" text="dark">
                  <Card.Body>
                    <Card.Title as="div">
                      2 - Actes médicaux associés
                    </Card.Title>
                    <Card.Text>
                      <small>
                        Renseignez ci-dessous les actes médicaux associés au
                        groupe "{formData.name}".
                      </small>
                    </Card.Text>
                    {proceduresForm.map((procedure, index) =>
                      procedure.deleted ? (
                        <div key={index}></div>
                      ) : (
                        <Row
                          key={index}
                          className="g-2 align-items-center"
                          style={{ marginTop: 0, marginBottom: '15px' }}
                        >
                          <Col md={12}>
                            <Row className="align-items-baseline">
                              <Col md={10}>
                                <Row>
                                  <Col>
                                    <Form.Group className="mb-0">
                                      <Form.Control
                                        type="text"
                                        placeholder="Exemple : Honoraires médicaux - médecin généraliste..."
                                        value={procedure.name}
                                        name="name"
                                        onChange={e =>
                                          handleProcedureChange(e, index)
                                        }
                                      />
                                    </Form.Group>
                                    <InputGroup className="mb-2 mt-2">
                                      <InputGroup.Text id="basic-addon1">
                                        Ordre :
                                      </InputGroup.Text>
                                      <FormControl
                                        placeholder="1"
                                        name="order"
                                        min="1"
                                        max={proceduresForm.length}
                                        onKeyDown={e => {
                                          e.preventDefault();
                                        }}
                                        value={procedure.order}
                                        className="text-end"
                                        type="number"
                                        onChange={e =>
                                          handleProcedureChange(e, index)
                                        }
                                      />
                                      <InputGroup.Text>
                                        /{proceduresForm.length}
                                      </InputGroup.Text>
                                    </InputGroup>
                                  </Col>
                                </Row>
                                {procedure.formulas &&
                                procedure.formulas.length > 0 ? (
                                  formulas.map(formula => {
                                    return procedure.formulas.map(
                                      (
                                        procedureFormula,
                                        indexProcedureFormula
                                      ) => {
                                        if (
                                          formula.id === procedureFormula.id
                                        ) {
                                          return (
                                            <Row
                                              className="mt-2 mb-2 align-items-center"
                                              accessKey={indexProcedureFormula}
                                              key={indexProcedureFormula}
                                            >
                                              <Col>
                                                <span
                                                  style={{
                                                    paddingLeft: '18px'
                                                  }}
                                                >
                                                  <small>
                                                    Couverture{' '}
                                                    <b>{formula.title}</b>{' '}
                                                  </small>
                                                </span>
                                              </Col>
                                              <Col>
                                                <Form.Group className="mb-0">
                                                  <Form.Control
                                                    type="text"
                                                    placeholder="Montant de la couverture pour cet acte"
                                                    value={
                                                      procedureFormula.pivot
                                                        .coverage
                                                    }
                                                    name="coverage"
                                                    size="sm"
                                                    onChange={e =>
                                                      handleProcedureChange(
                                                        e,
                                                        index,
                                                        indexProcedureFormula
                                                      )
                                                    }
                                                  />
                                                </Form.Group>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                      }
                                    );
                                  })
                                ) : (
                                  <Row>
                                    <Col>
                                      <small>
                                        <small>
                                          <b className="text-warning">
                                            Vous pourrez configurer la
                                            couverture pour chaque
                                            complémentaire après enregistrement
                                            du formulaire.
                                          </b>
                                        </small>
                                      </small>
                                    </Col>
                                  </Row>
                                )}
                              </Col>
                              <Col md={2} className="text-center">
                                <IconButton
                                  variant="danger"
                                  icon="trash"
                                  className="mb-1"
                                  iconAlign="right"
                                  transform="shrink-3"
                                  size="sm"
                                  onClick={() => {
                                    removeProcedure(index);
                                  }}
                                ></IconButton>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      )
                    )}
                    <Row
                      className="g-2 align-items-center"
                      style={{ marginTop: 15 }}
                    >
                      <Col md>
                        <IconButton
                          variant="primary"
                          icon="plus"
                          className="mb-1"
                          iconAlign="left"
                          transform="shrink-3"
                          size="sm"
                          onClick={() => {
                            addProcedure();
                          }}
                        >
                          Ajouter un {proceduresForm.length > 0 && 'autre '}
                          acte médical
                        </IconButton>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
          {showStep3 && formData.name && (
            <Row className="g-4 mt-0">
              <Col md>
                <Card bg="light" text="dark">
                  <Card.Body>
                    <Card.Title as="div">3 - Finalisation</Card.Title>
                    <Card.Text>
                      <small>
                        Vous avez terminé ? Cliquez sur le bouton ci-dessous
                        pour valider :
                      </small>
                    </Card.Text>
                    <Form.Group
                      className="mt-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Button
                        type="submit"
                        size="sm"
                        disabled={buttonIsLoading}
                        onClick={e => {
                          submit(e);
                        }}
                      >
                        {buttonIsLoading
                          ? 'Enregistrement en cours...'
                          : 'Enregistrer les actes'}
                      </Button>
                    </Form.Group>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
          {selectedCategory && (
            <IconButton
              variant="secondary"
              icon="times"
              className="mb-1"
              iconAlign="left"
              transform="shrink-3"
              size="sm"
              style={{ marginTop: 20, float: 'right' }}
              onClick={() => setShowManage(false)}
            >
              Annuler les modifications
            </IconButton>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

Manage.propTypes = {
  setTrigger: PropTypes.any,
  selectedCategory: PropTypes.any,
  setSelectedCategory: PropTypes.any,
  showManage: PropTypes.bool,
  setShowManage: PropTypes.any,
  formulas: PropTypes.any,
  setDefaultAccordionActive: PropTypes.any,
  buttonIsLoading: PropTypes.bool,
  setButtonIsLoading: PropTypes.any,
  category: PropTypes.string,
  procedureCategories: PropTypes.any
};
