import React from 'react';
import PropTypes from 'prop-types';
import ActionButton from '../../../common/ActionButton';
import { useNavigate } from 'react-router';

export default function Edit({ model, category }) {
  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/formulas/${category}/${model.id}`;
    navigate(path);
  };

  return (
    <ActionButton
      icon="edit"
      variant="action"
      className="p-0 me-2"
      title="Modifier la complémentaire"
      onClick={() => routeChange()}
    />
  );
}

Edit.propTypes = {
  setTrigger: PropTypes.func,
  model: PropTypes.any,
  category: PropTypes.string
};
