import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import { toast } from 'react-toastify';
import SoftBadge from '../../../common/SoftBadge';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';

function getApiUrl() {
  if (process.env.NODE_ENV === 'production') {
    return 'https://api.mnspf.fr/api';
  } else {
    return 'http://127.0.0.1:8000/api';
  }
}

const DropUploader = ({
  endpoint,
  file,
  setFile,
  accept,
  showAsImage,
  fileContext
}) => {
  const [fakeFile, setFakeFile] = useState('');
  const serviceUrl = `${getApiUrl()}/${endpoint}?file_context=${fileContext}`;
  const getUploadParams = () => {
    return {
      url: serviceUrl,
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('accessToken')
      }
    };
  };

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, remove }, status) => {
    if (status === 'headers_received') {
      setFile('');
      setFakeFile(meta.name);
      toast('Fichier enregistré');
      remove();
    } else if (status === 'aborted') {
      toast('Une erreur est survenue, veuillez réessayer plus tard');
    }
  };

  return (
    <>
      {file && !fakeFile && (
        <>
          {showAsImage ? (
            <Image
              src={file}
              thumbnail
              height="150px"
              width="150px"
              className="me-2 mb-3 mt-2"
            />
          ) : (
            <SoftBadge bg="primary" className="me-2 mb-3">
              <a href={file} target="_blank" rel="noreferrer">
                {file.substring(file.lastIndexOf('/') + 1)}
              </a>
            </SoftBadge>
          )}
        </>
      )}

      {!file && fakeFile && (
        <SoftBadge bg="primary" className="me-2 mb-3">
          {fakeFile}
        </SoftBadge>
      )}

      <Dropzone
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        maxFiles={1}
        multiple={false}
        canCancel={false}
        inputContent={
          file
            ? 'Choisir un autre fichier ou glisser ici'
            : 'Choisir un fichier ou glisser ici'
        }
        styles={{
          inputLabel: { fontSize: '14px', fontFamily: 'Poppins' },
          dropzone: { minHeight: '69px' }
        }}
        accept={accept ? accept : '*'}
      />
    </>
  );
};

export default DropUploader;

DropUploader.propTypes = {
  endpoint: PropTypes.string,
  file: PropTypes.string,
  setFile: PropTypes.func,
  accept: PropTypes.any,
  showAsImage: PropTypes.bool,
  fileContext: PropTypes.string
};
