import React, { useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  FormControl,
  InputGroup,
  Offcanvas,
  Row
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import FormAlert from '../../global/forms/FormAlert';
import { toast } from 'react-toastify';
import IconButton from '../../../common/IconButton';
import { PutExamples } from '../../../../services/ProcedureService';

export default function Manage({
  setTrigger,
  selectedCategory,
  setSelectedCategory,
  showManage,
  setShowManage,
  formulas,
  setDefaultAccordionActive,
  buttonIsLoading,
  setButtonIsLoading
}) {
  const [errorForm] = useState(false);
  const [showStep3, setShowStep3] = useState(false);
  const [examplesForm, setExamplesForm] = useState([]);

  const handleOpen = () => {
    if (selectedCategory) {
      if (selectedCategory.examples.length) {
        let list = [...examplesForm];
        selectedCategory.examples.map(example => {
          list.push({
            name: example.name,
            order: example.order,
            average_price: example.average_price,
            average_support: example.average_support,
            formulas: example.formulas,
            id: example.id
          });
        });
        setExamplesForm(list);
      }
      setShowStep3(true);
    }
  };

  const handleClose = () => {
    setShowManage(false);
    setExamplesForm([]);
    setSelectedCategory(false);
    setShowStep3(false);
  };

  const submit = async e => {
    setButtonIsLoading(true);
    e.preventDefault();

    let responseExamples = await PutExamples(selectedCategory.id, examplesForm);
    if (responseExamples.success === true) {
      toast('Groupe enregistré');
      setButtonIsLoading(false);
      setShowManage(!showManage);
      setExamplesForm([]);
      setShowStep3(false);
      setDefaultAccordionActive(selectedCategory.id);
      setSelectedCategory(false);
      setTrigger(Date.now());
    }
  };

  const handleExampleChange = (e, index, indexProcedureFormula) => {
    const list = [...examplesForm];
    // check si l'user a essayé d'effacer la valeur de l'input -> on remplace par 0
    if (
      e.target.name === 'coverage' ||
      e.target.name === 'average_price' ||
      e.target.name === 'average_support'
    ) {
      if (e.target.value === '') {
        e.target.value = 0;
      }
    }
    if (e.target.name === 'coverage') {
      list[index].formulas[indexProcedureFormula].pivot.coverage =
        e.target.value;
    } else if (e.target.name === 'name') {
      list[index].name = e.target.value;
    } else if (e.target.name === 'order') {
      list[index].order = e.target.value;
    } else if (e.target.name === 'average_price') {
      list[index].average_price = e.target.value;
    } else if (e.target.name === 'average_support') {
      list[index].average_support = e.target.value;
    }
    checkShowStep3(list);
    setExamplesForm(list);
  };

  const addExample = () => {
    const list = [...examplesForm];
    list.push({
      name: '',
      order: 1,
      average_price: 0,
      average_support: 0
    });
    setExamplesForm(list);
    checkShowStep3(list);
  };

  const removeExample = index => {
    const list = [...examplesForm];
    list[index].deleted = true;
    setExamplesForm(list);
    checkShowStep3(list);
  };

  const checkShowStep3 = list => {
    if (list.length > 0) {
      setShowStep3(true);
      list.map(procedure => {
        if (procedure.name === '' && !procedure.deleted) {
          setShowStep3(false);
        }
      });
    } else {
      setShowStep3(false);
    }
  };

  return (
    <>
      <Offcanvas
        placement={'end'}
        show={showManage}
        onShow={handleOpen}
        onHide={handleClose}
        style={{ width: 700, backgroundColor: '#EDF2F9' }}
      >
        <Offcanvas.Header closeButton={!selectedCategory}>
          <Row className="align-items-center" style={{ width: '100%' }}>
            <Col>
              <Offcanvas.Title>
                Modifier le groupe "{selectedCategory.name}"
              </Offcanvas.Title>
            </Col>
          </Row>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {errorForm && <FormAlert variant="danger" data={errorForm} />}
          <Row className="mt-0">
            <Col md>
              <Card bg="light" text="dark">
                <Card.Body>
                  <Card.Title as="div">
                    1 - Exemples d'actes médicaux associés
                  </Card.Title>
                  <Card.Text>
                    <small>
                      Renseignez ci-dessous les exemples d'actes médicaux
                      associés au groupe "{selectedCategory.name}".
                    </small>
                  </Card.Text>
                  {examplesForm.map((example, index) =>
                    example.deleted ? (
                      <div key={index}></div>
                    ) : (
                      <Row
                        key={index}
                        className="g-2 align-items-center"
                        style={{ marginTop: 0, marginBottom: '15px' }}
                      >
                        <Col md={12}>
                          <Row className="align-items-baseline">
                            <Col md={10}>
                              <Row>
                                <Col>
                                  <Form.Group className="mb-0">
                                    <Form.Control
                                      type="text"
                                      placeholder="Exemple : Forfait journalier hospitalier en court séjour..."
                                      value={example.name}
                                      name="name"
                                      onChange={e =>
                                        handleExampleChange(e, index)
                                      }
                                    />
                                  </Form.Group>
                                  <InputGroup className="mb-2 mt-2">
                                    <InputGroup.Text id="basic-addon1">
                                      Ordre :
                                    </InputGroup.Text>
                                    <FormControl
                                      placeholder="1"
                                      name="order"
                                      min="1"
                                      max={examplesForm.length}
                                      onKeyDown={e => {
                                        e.preventDefault();
                                      }}
                                      value={example.order}
                                      className="text-end"
                                      type="number"
                                      onChange={e =>
                                        handleExampleChange(e, index)
                                      }
                                    />
                                    <InputGroup.Text>
                                      /{examplesForm.length}
                                    </InputGroup.Text>
                                  </InputGroup>
                                  <InputGroup className="mb-2 mt-2">
                                    <InputGroup.Text id="basic-addon1">
                                      Prix moyen de l'acte :
                                    </InputGroup.Text>
                                    <FormControl
                                      placeholder="10"
                                      name="average_price"
                                      value={example.average_price}
                                      className="text-end"
                                      type="number"
                                      onChange={e =>
                                        handleExampleChange(e, index)
                                      }
                                    />
                                    <InputGroup.Text>€</InputGroup.Text>
                                  </InputGroup>
                                  <InputGroup className="mb-2 mt-2">
                                    <InputGroup.Text id="basic-addon1">
                                      Prise en charge Sécurité sociale :
                                    </InputGroup.Text>
                                    <FormControl
                                      placeholder="10"
                                      name="average_support"
                                      value={example.average_support}
                                      className="text-end"
                                      type="number"
                                      onChange={e =>
                                        handleExampleChange(e, index)
                                      }
                                    />
                                    <InputGroup.Text>€</InputGroup.Text>
                                  </InputGroup>
                                </Col>
                              </Row>
                              {example.formulas &&
                              example.formulas.length > 0 ? (
                                formulas.map(formula => {
                                  return example.formulas.map(
                                    (exampleFormula, indexExampleFormula) => {
                                      if (formula.id === exampleFormula.id) {
                                        return (
                                          <Row
                                            className="mt-2 mb-2 align-items-center"
                                            accessKey={indexExampleFormula}
                                            key={indexExampleFormula}
                                          >
                                            <Col>
                                              <span
                                                style={{
                                                  paddingLeft: '18px'
                                                }}
                                              >
                                                <small>
                                                  Prise en ch.{' '}
                                                  <b>{formula.title}</b>{' '}
                                                </small>
                                              </span>
                                            </Col>
                                            <Col>
                                              <InputGroup className="mb-1 mt-1">
                                                <Form.Control
                                                  type="number"
                                                  placeholder="Montant de la prise en charge pour cet exemple"
                                                  value={
                                                    exampleFormula.pivot
                                                      .coverage
                                                  }
                                                  name="coverage"
                                                  className="text-end"
                                                  size="sm"
                                                  onChange={e =>
                                                    handleExampleChange(
                                                      e,
                                                      index,
                                                      indexExampleFormula
                                                    )
                                                  }
                                                />
                                                <InputGroup.Text>
                                                  €
                                                </InputGroup.Text>
                                              </InputGroup>
                                            </Col>
                                          </Row>
                                        );
                                      }
                                    }
                                  );
                                })
                              ) : (
                                <Row>
                                  <Col>
                                    <small>
                                      <small>
                                        <b className="text-warning">
                                          Vous pourrez configurer la couverture
                                          pour chaque complémentaire après
                                          enregistrement du formulaire.
                                        </b>
                                      </small>
                                    </small>
                                  </Col>
                                </Row>
                              )}
                            </Col>
                            <Col md={2} className="text-center">
                              <IconButton
                                variant="danger"
                                icon="trash"
                                className="mb-1"
                                iconAlign="right"
                                transform="shrink-3"
                                size="sm"
                                onClick={() => {
                                  removeExample(index);
                                }}
                              ></IconButton>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    )
                  )}
                  <Row
                    className="g-2 align-items-center"
                    style={{ marginTop: 15 }}
                  >
                    <Col md>
                      <IconButton
                        variant="primary"
                        icon="plus"
                        className="mb-1"
                        iconAlign="left"
                        transform="shrink-3"
                        size="sm"
                        onClick={() => {
                          addExample();
                        }}
                      >
                        Ajouter un {examplesForm.length > 0 && 'autre '}
                        exemple d'acte médical
                      </IconButton>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {showStep3 && (
            <Row className="g-4 mt-0">
              <Col md>
                <Card bg="light" text="dark">
                  <Card.Body>
                    <Card.Title as="div">2 - Finalisation</Card.Title>
                    <Card.Text>
                      <small>
                        Vous avez terminé ? Cliquez sur le bouton ci-dessous
                        pour valider :
                      </small>
                    </Card.Text>
                    <Form.Group
                      className="mt-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Button
                        type="submit"
                        size="sm"
                        disabled={buttonIsLoading}
                        onClick={e => {
                          submit(e);
                        }}
                      >
                        {buttonIsLoading
                          ? 'Enregistrement en cours...'
                          : "Enregistrer les exemples d'actes"}
                      </Button>
                    </Form.Group>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
          {selectedCategory && (
            <IconButton
              variant="secondary"
              icon="times"
              className="mb-1"
              iconAlign="left"
              transform="shrink-3"
              size="sm"
              style={{ marginTop: 20, float: 'right' }}
              onClick={() => window.location.reload(false)}
              // onClick={() => setShowManage(false)}
            >
              Annuler les modifications
            </IconButton>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

Manage.propTypes = {
  setTrigger: PropTypes.any,
  selectedCategory: PropTypes.any,
  setSelectedCategory: PropTypes.any,
  showManage: PropTypes.bool,
  setShowManage: PropTypes.any,
  formulas: PropTypes.any,
  setDefaultAccordionActive: PropTypes.any,
  buttonIsLoading: PropTypes.bool,
  setButtonIsLoading: PropTypes.any
};
