import React, { useEffect, useState } from 'react';
import ManageDetails from './ManageDetails';
import ManageHeader from './ManageHeader';
import ManageUpload from './ManageUpload';
import { Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import ManageOtherInfo from './ManageOtherInfo';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  PostFormula,
  PutFormula,
  ShowFormula
} from '../../../../../services/FormulaService';
import { useNavigate, useParams } from 'react-router';
import FormAlert from '../../../global/forms/FormAlert';
import PropTypes from 'prop-types';

export default function FormulasShow({ category }) {
  const getCategoryLabel = () => {
    if (category === 'complementaires') {
      return 'complémentaire';
    } else if (category === 'surcomplementaires') {
      return 'sur-complémentaire';
    }
  };

  const { id } = useParams();
  const navigate = useNavigate();
  const [formula, setFormula] = useState({});
  const [fileBrochure, setFileBrochure] = useState('');
  const [fileLogo, setFileLogo] = useState('');
  const [trigger, setTrigger] = useState(Date.now());
  const [isLoading, setIsLoading] = useState(true);
  const [errorForm, setErrorForm] = useState(false);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    title: `Nouvelle ${getCategoryLabel()}`,
    content: '',
    published: 0,
    price: '',
    category_name: category
  });

  useEffect(() => {
    const fetchData = async () => {
      const response = await ShowFormula(id);
      if (response.success === true) {
        setFormula(response.data);
        setFormData({
          title: response.data.title,
          published: response.data.published,
          content: response.data.content,
          price: response.data.price
        });
        setFileBrochure(response.data.file_url);
        setFileLogo(response.data.logo_url);
        setIsLoading(false);
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    if (id) {
      setIsLoading(true);
      fetchData();
    } else {
      setIsLoading(false);
    }
  }, [trigger]);
  const { register, handleSubmit } = useForm(formData);

  const onSubmit = async () => {
    setButtonIsLoading(true);
    let response = false;
    if (id) {
      response = await PutFormula(formula.id, formData);
    } else {
      response = await PostFormula(formData);
    }
    setErrorForm(response.validator);
    setButtonIsLoading(false);
    if (response.success === true) {
      toast(`${getCategoryLabel()} enregistrée`);
      setButtonIsLoading(false);
      setTrigger(Date.now());
      if (!id) {
        window.location.href = `/formulas/${category}/${response.data.id}`;
        //navigate(`/formulas/${response.data.ID}`);
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <Spinner animation="border" size="sm" />
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="g-3">
            <Col xs={12}>
              <ManageHeader
                formData={formData}
                buttonIsLoading={buttonIsLoading}
                category={category}
              />
            </Col>
            <Col lg={8}>
              {errorForm && <FormAlert variant="danger" data={errorForm} />}
              <ManageDetails
                formData={formData}
                setFormData={setFormData}
                formula={formula}
                getCategoryLabel={getCategoryLabel()}
              />
              {id ? (
                <ManageUpload
                  formData={formData}
                  setFormData={setFormData}
                  formula={formula}
                  fileBrochure={fileBrochure}
                  setFileBrochure={setFileBrochure}
                  fileLogo={fileLogo}
                  setFileLogo={setFileLogo}
                />
              ) : (
                <Card className="mb-3">
                  <Card.Header as="h5">Plaquette</Card.Header>
                  <Card.Body className="bg-light">
                    <p style={{ fontSize: 'small', fontStyle: 'italic' }}>
                      Enregistrez la {getCategoryLabel()} pour pouvoir ajouter
                      une pièce jointe
                    </p>
                  </Card.Body>
                </Card>
              )}
            </Col>
            <Col lg={4}>
              <div className="sticky-sidebar">
                <ManageOtherInfo
                  register={register}
                  formula={formula}
                  navigate={navigate}
                  formData={formData}
                  setFormData={setFormData}
                  buttonIsLoading={buttonIsLoading}
                  category={category}
                  getCategoryLabel={getCategoryLabel()}
                />
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
}

FormulasShow.propTypes = {
  category: PropTypes.string
};
