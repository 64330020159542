import React, { useEffect, useState } from 'react';
import { Accordion, Button, Card, Col, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import PageHeader from '../../../common/PageHeader';
import {
  IndexProcedures,
  ShowProcedureCategory
} from '../../../../services/ProcedureService';
import { Table } from 'react-bootstrap';
import Manage from './Manage';
import IconButton from '../../../common/IconButton';
import { IndexFormula } from '../../../../services/FormulaService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ProceduresIndex({ category }) {
  const [procedureCategories, setProcedureCategories] = useState([]);
  const [trigger, setTrigger] = useState(Date.now());
  const [isLoading, setIsLoading] = useState(true);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(false);
  const [formulas, setFormulas] = useState('');
  const [showManage, setShowManage] = useState(false);
  const [defaultAccordionActive, setDefaultAccordionActive] = useState(false);

  const getCategoryLabel = () => {
    if (category === 'complementaires') {
      return 'complémentaire';
    } else if (category === 'surcomplementaires') {
      return 'sur-complémentaire';
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await IndexProcedures(category);
      if (response.success === true) {
        setProcedureCategories(response.data);
        if (response.data.length > 0 && !defaultAccordionActive) {
          setDefaultAccordionActive(response.data[0].id);
        }
        const responseFormulas = await IndexFormula(category);
        if (responseFormulas.success === true) {
          setFormulas(responseFormulas.data);
          setIsLoading(false);
        } else {
          toast('Une erreur est survenue, veuillez réessayer plus tard');
        }
      } else {
        toast('Une erreur est survenue, veuillez réessayer plus tard');
      }
    };
    setIsLoading(true);
    fetchData();
  }, [`${category}-${trigger}`]);

  const handleManageShow = category => {
    setButtonIsLoading(true);
    let id = category.id;
    const fetchData = async () => {
      const response = await ShowProcedureCategory(id);
      if (response.success === true && response.data) {
        return response.data;
      }
    };
    fetchData().then(data => {
      setSelectedCategory(data);
      setShowManage(true);
      setButtonIsLoading(false);
    });
  };

  return (
    <>
      <PageHeader
        title="Gestion des actes médicaux"
        description={`Gérez ici l'ensemble des actes médicaux couverts par les ${getCategoryLabel()}s. ${
          category === 'complementaires'
            ? `Vous pouvez ajouter un nouveau groupe d'actes (exemple : Soins courants, Hospitalisation...). Pour chaque groupe, ajoutez les actes médicaux associés. Enfin, gérez la couverture de chaque acte pour chaque ${getCategoryLabel()}.`
            : ''
        }`}
        className="mb-3"
      />
      <Card>
        <Card.Body>
          {isLoading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            <>
              <Row className="mb-3">
                <Col className="text-end">
                  {category === 'complementaires' && (
                    <IconButton
                      variant="falcon-default"
                      size="sm"
                      icon="plus"
                      transform="shrink-3"
                      className=""
                      onClick={() => setShowManage(true)}
                      disabled={buttonIsLoading}
                    >
                      <span className="d-none d-sm-inline-block ms-1">
                        Ajouter un groupe
                      </span>
                    </IconButton>
                  )}
                  <Manage
                    setTrigger={setTrigger}
                    selectedCategory={selectedCategory}
                    setSelectedCategory={setSelectedCategory}
                    showManage={showManage}
                    setShowManage={setShowManage}
                    formulas={formulas}
                    defaultAccordionActive={defaultAccordionActive}
                    setDefaultAccordionActive={setDefaultAccordionActive}
                    buttonIsLoading={buttonIsLoading}
                    setButtonIsLoading={setButtonIsLoading}
                    category={category}
                    procedureCategories={procedureCategories}
                  />
                </Col>
              </Row>
              <Accordion
                id="accordionFaq"
                className="border rounded overflow-hidden"
                defaultActiveKey={`${defaultAccordionActive}`}
              >
                {procedureCategories.map(category => (
                  <Accordion.Item
                    eventKey={`${category.id}`}
                    key={`${category.id}`}
                  >
                    <Accordion.Header>
                      <span style={{ fontWeight: 700 }}>{category.name}</span>
                    </Accordion.Header>
                    <Accordion.Body style={{ padding: '10px 0' }}>
                      <Table className="mt-1">
                        <thead>
                          <tr>
                            <td valign="middle">
                              <Button
                                onClick={() => handleManageShow(category)}
                                size="sm"
                                variant="falcon-primary"
                                className="me-2 mb-1"
                                disabled={buttonIsLoading}
                              >
                                {buttonIsLoading ? (
                                  'Chargement…'
                                ) : (
                                  <>
                                    <FontAwesomeIcon
                                      icon="cog"
                                      className="me-1"
                                    />
                                    Gérer
                                  </>
                                )}
                              </Button>
                            </td>
                            {formulas.map((formula, index) => (
                              <td
                                key={index}
                                style={{ fontSize: '14px', padding: '6px' }}
                                className="text-center"
                                valign="middle"
                              >
                                <b>{formula.title}</b>
                              </td>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {category.procedures.map((procedure, indexP) => (
                            <tr key={indexP}>
                              <td
                                style={{
                                  fontSize: '14px',
                                  padding: '6px 0 6px 20px',
                                  width: '150px'
                                }}
                                valign="middle"
                              >
                                {parse(procedure.name)}
                              </td>
                              {formulas.map(formula => {
                                return procedure.formulas.map(
                                  (procedureFormula, index) => {
                                    if (formula.id === procedureFormula.id) {
                                      return (
                                        <td
                                          style={{
                                            fontSize: '14px',
                                            padding: '6px'
                                          }}
                                          key={index}
                                          className="text-center"
                                          valign="middle"
                                        >
                                          {procedureFormula.pivot.coverage
                                            ? procedureFormula.pivot.coverage
                                            : 'À compléter'}
                                        </td>
                                      );
                                    }
                                  }
                                );
                              })}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
}

ProceduresIndex.propTypes = {
  created_at: PropTypes.any,
  category: PropTypes.string
};
